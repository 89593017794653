import { FontStyleModel } from '@/models/general/FontModel'
import Palette from '~/models/palette/Palette'
import { getBorder } from '@/models/general/BorderModel'

export class ButtonStyleModel {
  constructor() {
    // this.width = null
    this.height = null
    this.color = ''
    this.backgroundColor = ''
    this.backgroundColorOnHover = ''
    this.borderColorOnHover = null
    this.textAlign = null
    this.padding = null
    this.margin = null
    this.borderStyle = null
    this.borderColor = null
    this.borderWidth = null
    this.borderRadius = null
    this.fontStyle = null
  }

  fromData({
    // width = null,
    height = null,
    color = null,
    bgColor = '',
    bgColorOnHover = null,
    alignment = null,
    padding = null,
    margin = null,
    borderColorOnHover = null,
    borderColor = null,
    borderProperties = null,
    fontProperties = null,
  }) {
    const palette1 = new Palette()
    palette1.checkColor(color)
    this.color = palette1.color

    const palette2 = new Palette()
    palette2.checkColor(bgColor)
    this.backgroundColor = palette2.color

    const palette3 = new Palette()
    palette3.checkColor(bgColorOnHover)
    this.backgroundColorOnHover = palette3.color

    const palette4 = new Palette()
    palette4.checkColor(borderColorOnHover)
    this.borderColorOnHover = palette4.color

    this.textAlign = alignment

    // if (width) this.width = `${width}%`

    if (height) {
      this.height =
        height.type === 'px' || height.type === '%'
          ? `${height.value}${height.type}`
          : `${height.type}`
    }

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (borderProperties) {

      const { borderWidth, borderRadius, borderStyle, borderColor } = getBorder(borderProperties)
      this.borderWidth = borderWidth
      this.borderRadius = borderRadius
      this.borderStyle = borderStyle
      this.borderColor = borderColor
    }

    if (fontProperties) {
      const fontStyle = new FontStyleModel()
      fontStyle.fromData(fontProperties)
      this.fontStyle = fontStyle
    }
  }
}
